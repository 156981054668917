<template>
  <content-loader v-if="showContentLoader" />
  <div
    ref="contentRef"
    class="page"
  >
    <riseup-header />
    <div
      v-if="currentPage === 1"
      class="page-1"
    >
      <div
        class="content"
      >
        <h1>Your amazing insights await!</h1>
        <div
          v-for="(insight, index) in firstInsights"
          :key="index"
          class="insights-container"
        >
          <div
            :class="['insight-box', getColorClass(index)]"
            @click="handleInsightClicked(insight)"
          >
            <div class="emoji">
              {{ insight.emoji || '💰' }}
            </div>
            <p class="title">
              {{ insight.opening }}
            </p>
            <p class="analysis">
              <b>{{ insight.analysis }}</b>
            </p>
            <p class="insight">
              {{ insight.insight }}
            </p>
          </div>
        </div>
      </div>
      <div class="green-bottom">
        <img
          src="../../assets/green-bottom-top.svg"
        >
        <button
          class="blue-button"
          @click="handleTrialButtonClick"
        >
          Start your 3 month free trial now!
        </button>
        <p>
          <b>To unlock the full power of our technology, start your free trial now.</b>
        </p>
        <p>
          With RiseUp, you'll get a complete view of all your accounts, income and spending,
          plus personalised insights and real-time recommendations.
          You also get in-app and community support to help you stay on track.
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import RiseupHeader from '../Common/RiseupHeader.vue';
import ContentLoader from '../Common/ContentLoader.vue';
import Segment from '../../Segment';
import useContentLoader from '../../composables/useContentLoader';
import { useInsights } from '../../services/insightsService';
import { useUtm } from '../../services/utmService';

const { insights, populateInsights } = useInsights();
const currentPage = ref(1);
const contentRef = ref(null);
const route = useRoute();
const { utmParams } = useUtm();

const {
  showContentLoader,
} = useContentLoader();
const firstInsights = ref([]);

const getColorClass = index => {
  const colors = ['green-light', 'pink-light', 'blue-light', 'blue-light', 'pink-light'];
  return colors[index % colors.length];
};

const handleInsightClicked = insight => {
  Segment.trackUserInteraction('OtsInsightClicked', { insightIndex: insight.index });
};

const handleTrialButtonClick = () => {
  Segment.trackUserInteraction('OtsStartTrialClicked');
  window.location.href = 'https://start.letsriseup.com/?utm_source=ots&coupon=promo_1PctHEI1mBb8n2B7YmW03EcC';
};

onMounted(async () => {
  const queryParam = route.query || null;

  if (!insights.value || insights.value.length === 0) {
    await populateInsights();
  }

  if (insights.value && insights.value.length > 0) {
    firstInsights.value = insights.value.slice(0, 3);
  }
  Segment.trackUserGot('OtsVanillaSuccessPageEntered', { fromEmail: queryParam ? queryParam.fromEmail : false, utm_params: utmParams.value });
});

</script>

<style lang="scss" scoped>
@import '../../scss/riseup-vars';
@import '../../scss/riseup-vars';

.content {
  margin-top: 30px;
  padding-left: 25px;
  padding-right: 25px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.page {
  background-color: $main_background_color;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  scroll-behavior: smooth;
}

.content h1 {
  padding-bottom: 40px;
  color: #202125;
  font-size: 28px;
  font-family: GrenetteSemiBold;
  font-weight: 600;
  line-height: 39px;
  word-wrap: break-word;
}

.blue-button {
  background-color: #5D7AFD;
  border-radius: 10px;
  height: 48px;
  color: white;
  padding: 10px 20px;
  border: none;
  margin-bottom: 20px;
  margin-top: 20px;
  width: 90%;
  cursor: pointer;
  font-size: 15px;
  font-family: CircularStdBook;
  font-weight: 900;
}

.footer {
  text-align: center;
  position: sticky;
  bottom: 0;
  padding-top: 10px;
  background: linear-gradient(to bottom, transparent , $main_background_color 20%,  $main_background_color );
}

.insight-box {
  width: 100%;
  padding: 21px 28px 40px;
  margin-bottom: 20px;
  border-radius: 20px;
  border: 1px rgba(55, 53, 44, 0.08) solid;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  display: inline-flex;
}

.insights-container {
  display: flex;
  flex-direction: column;
}

.green-light {
  background-color: $green_light_background_color;
}

.pink-light {
  background-color: $pink_light_background_color;
}

.blue-light {
  background-color: $blue_light_background_color;
}

.green-bottom {
  height: 970px;
  width: 100%;
  margin-top: 40px;
  background-color: $green_background_color;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.green-bottom .blue-button {
  height: 64px;
  margin-bottom: 10px;
  box-shadow: 0px 12px 24px rgba(32, 33, 37, 0.12);
}

.green-bottom img {
  margin-top: 60px;
  width: 100%;
  padding-left: 10px;
}

.green-bottom p {
  color: #E8F4BA;
  font-size: 18px;
  font-family: CircularStd;
  line-height: 28px;
  word-wrap: break-word;
  width: 90%;
  margin-top: 43px;
}

.insight-box .emoji {
  font-size: 32px;
  font-family: CircularStd,serif;
}

.insight-box .title {
  font-size: 28px;
  font-family: GrenetteSemiBold,serif;
  line-height: 40px;
}

.number-circle {
  width: 24px;
  height: 24px;
  background: #205441;
  border-radius: 9999px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: Arial,serif;
  font-weight: 700;
  word-wrap: break-word;
}
</style>

<template>
  <responsive-page>
    <template v-slot:page-content>
      <router-view />
    </template>
  </responsive-page>
  <modals-container />
</template>

<script lang="ts" setup>
import '@lottiefiles/lottie-player'; // Enables lottie-player everywhere
import { ModalsContainer } from 'vue-final-modal';
import ResponsivePage from './components/Common/ResponsivePage.vue';
import { useInsightsService } from './services/insightsService';
import { useUtmService } from './services/utmService'; // Adjust the path as needed

useInsightsService();
useUtmService();
</script>

<style lang="scss">
@import '@riseupil/base-ui/src/scss/icons-font-classes';
@import '@riseupil/base-ui/src/scss/riseup-colors';
@import '@riseupil/base-ui/src/scss/fonts';

#app {
  direction: ltr;
  font-family: Grenette, CircularStd, sans-serif;
  letter-spacing: 0.4px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  display: flex;
  flex-direction: row;
  justify-content: center;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: 100%;

  input, textarea, select {
    font-family: inherit;
  }

  .page-and-header {
    // This makes the page have full height
    & > div:nth-child(2) {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }
}
</style>

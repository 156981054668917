import axios from 'axios';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let config: any = null;

export default {
  async fetch() {
    const response = await axios.get(`/static/config.json?t=${new Date().getTime()}`);
    config = response.data;
  },
  get() {
    return config;
  },
};

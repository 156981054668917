import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';

export default {
  getId() {
    let anonymousUid = localStorage.getItem('riseup.anonymousUid');
    if (!anonymousUid) {
      anonymousUid = `anon-${uuidv4()}`;
      localStorage.setItem('riseup.anonymousUid', anonymousUid);
      Cookies.set('ajs_anonymous_id', anonymousUid);
    }
    return anonymousUid;
  },
  reset() {
    const anonymousUid = `anon-${uuidv4()}`;
    localStorage.setItem('riseup.anonymousUid', anonymousUid);
    Cookies.set('ajs_anonymous_id', anonymousUid);

    return anonymousUid;
  },
};

import { clientWithoutInterceptors } from '../utils/http';

export async function isLoggedIn(): Promise<boolean> {
  const response = await clientWithoutInterceptors.get('/api/partial-auth/logged-in/', {
    validateStatus: status => status === 200 || status === 403,
  });
  return response.status === 200;
}

export async function logOut(): Promise<void> {
  await clientWithoutInterceptors.get('/api/logout');
}

<template>
  <div class="logout">
    <riseup-header />
  </div>
</template>

<script setup>
import { clientWithoutInterceptors } from '../../utils/http';
import Segment from '../../Segment';
import RiseupHeader from '../Common/RiseupHeader.vue';

Segment.trackUserGot('DescopeLogout');
const logout = async () => {
  try {
    await clientWithoutInterceptors.get('/api/logout');
    Segment.trackUserGot('DescopeLogoutSuccess');
  } catch (error) {
    console.log('Cant log out, were you logged in?', { error });
  }
  window.location.href = '/login';
};

// eslint-disable-next-line
logout().then(() => {});

</script>

<style lang="scss" scoped>
@import '../../scss/riseup-vars';

.logout {
  background-color: $main_background_color;
  height: 100%;
}
</style>

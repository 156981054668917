<template>
  <content-loader v-if="showContentLoader" />
  <div
    v-else
    class="signup"
  >
    <riseup-header :variant="utmVariant || utmParams['utm_variant']"/>
    <Descope
      :client="{ anonymousUserId: anonymousSession.getId(), utmParams, contextPageReferrer}"
      :error-transformer="errorTransformer"
      :flow-id="descopeFlow"
      @error="handleDescopeError"
      @success="handleSuccess"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch, onBeforeMount } from 'vue';
import { Descope, useSession } from '@descope/vue-sdk';
import _ from 'lodash';
import Cookies from 'js-cookie';
import { useRouter, useRoute } from 'vue-router';
import ContentLoader from '../Common/ContentLoader.vue';
import { clientWithoutInterceptors } from '../../utils/http';
import DDLogs from '../../DDLogs';
import RiseupHeader from '../Common/RiseupHeader.vue';
import Segment from '../../Segment';
import useContentLoader from '../../composables/useContentLoader';
import { errorTransformer, handleDescopeError } from '../../utils/errorHandling';
import config from '../../config';
import anonymousSession from '../../anonymousSession';
import { useUtm } from '../../services/utmService';

const { utmParams: utmParamsService, fetchUtmParams } = useUtm();
const utmVariant = computed(() => utmParamsService.value?.utm_variant ?? '');

const router = useRouter();
const {
  toggleLoader,
  showContentLoader,
} = useContentLoader();
toggleLoader(true);
watch(useSession().isLoading, async newVal => {
  toggleLoader(newVal);
});

const { sessionToken } = useSession();
const utmParams = _.pickBy(router.currentRoute.value.query, (value, key) => (key.startsWith('utm_') && value));
const contextPageReferrer = document.referrer;

const route = useRoute();

onBeforeMount(async () => {
  const queryParam = route.query || null;

  if (!queryParam['descope-login-flow']) {
    await Segment.reset();
    Segment.identifyAnonymousUser();
  }
});

const handleSuccess = async (e: CustomEvent) => {
  toggleLoader(true);
  DDLogs.info('DescopeOtsSignup handleSuccess');
  const token = sessionToken.value ?? e.detail.sessionJwt;

  if (!token) {
    throw new Error('Descope Signup flow failed to signup, user sessionJwt is empty');
  }

  const resp = await clientWithoutInterceptors.post(
    '/api/no-auth/descope/complete-descope-ots-signup',
    {
      utmParams,
      gclid: Cookies.get('gclid'),
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  const { product } = router.currentRoute.value.query;
  Segment.identifyCustomer(resp.data.customerId, resp.data.memberId);

  if (!e.detail.user.customAttributes.customerId) {
    Segment.trackUserGot('CustomerCreated_Marketing', {
      market: 'UK',
      product,
      gclid: Cookies.get('gclid'),
      customerId: resp.data.customerId,
      utmParams,
    });
  }

  await fetchUtmParams();

  window.location.href = config.get().otsConnectUrl;
};

const descopeFlow = computed(() => 'ots-signup');

const onMetaBrowser = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return (userAgent.indexOf('FBAN') > -1) || (userAgent.indexOf('FBAV') > -1) || (userAgent.indexOf('Instagram') > -1);
};

const currentHash = window.location.hash;

if (onMetaBrowser() && !currentHash) {
  window.location.hash = '#FBK';
}

</script>

<style lang="scss" scoped>
@import '../../scss/riseup-vars';

.signup {
  background-color: $main_background_color;
  height: 100%;
  overflow-y: auto;
  scroll-behavior: smooth;
}

</style>

<template>
  <div
    ref="scrollablePageMobile"
    class="responsive-page"
  >
    <div class="page-wrapper">
      <div
        ref="scrollablePage"
        class="page-and-header"
      >
        <slot name="page-content" />
      </div>
      <div class="footer-wrapper" />
    </div>
  </div>
</template>

<script>

export default {
  name: 'ResponsivePage',
};

const vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

window.addEventListener('resize', () => {
  // We execute the same script as before
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});
</script>

<style lang="scss" scoped>
@import '@riseupil/base-ui/src/scss/riseup-colors';

.responsive-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  scroll-behavior: smooth;
  position: fixed;

  .page-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);

    .page-and-header {
      position: relative;
      width: 100%;
      height: 100%;
    }

    .footer-wrapper {
      display: none;
    }
  }
}

@media (min-width: 450px) {
  .responsive-page {
    background-color: $riseup_white;

    .page-wrapper {
      width: 400px;
      display: flex;
      align-items: center;
      margin: calc(100vh * 0.05) 0;

      .page-and-header {
        margin-bottom: 20px;
        background-color: $riseup_white;
        min-height: inherit;
        height: calc(100vh - 100vh * 0.1 - 35px - 20px);
        border-radius: 3px;
        box-shadow: 0 2px 5px 0 rgba(166, 166, 166, 0.5);
        border: solid 1px #e7e7e7;
        overflow: scroll;
        overflow-x: hidden;
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: none; /* Firefox */
      }

      ::-webkit-scrollbar {
        width: 0;
        background: transparent;
      }

      .footer-wrapper {
        width: 100%;
        visibility: visible;
        display: inline-block;
        height: 35px;
      }
    }
  }
}
</style>

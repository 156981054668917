import _ from 'lodash';
import { MemberId, RiseupAccountId } from '@riseupil/costa-client';
import UAParser from 'ua-parser-js';
import Cookies from 'js-cookie';
import config from './config';
import DDLogs from './DDLogs';
import anonymousSession from './anonymousSession';

const staticData = {
  incognito: false,
  memberId: '',
};

// This event is a reserved event in segment, meant to be sent once upon use signup to enable matching between anonymousId and userId in segment
const SIGNED_UP_EVENT_NAME = 'Signed Up';

function _getSegmentSourceId(): string {
  return config.get().segmentSourceId;
}

/* eslint-disable */
const SERVICE_NAME = 'greta';
const uaParser = new UAParser();

const context = {
  service: SERVICE_NAME,
  browser: uaParser.getBrowser().name,
  //It's || 'desktop' because we uaParser.getDevice().type returns undefined for desktop
  deviceType: uaParser.getDevice().type || 'desktop',
};

const Segment = {
  install() {
    // This is copied from segment, so its types are ignored and there are vars (instead of lets)
    //@ts-ignore
    !function () {
      var analytics = window.analytics = window.analytics || [];
      if (!analytics.initialize) {
        if (analytics.invoked) {
          window.console && console.error && console.error('Segment snippet included twice.');
        } else {
          analytics.invoked = !0;
          analytics.methods =
            ['register', 'trackSubmit', 'trackClick', 'trackLink', 'trackForm', 'pageview', 'identify', 'reset', 'group', 'track', 'ready', 'alias', 'debug',
              'page', 'once', 'off', 'on', 'addSourceMiddleware', 'addIntegrationMiddleware', 'setAnonymousId', 'addDestinationMiddleware'];
          analytics.factory = function (e) {
            return function () {
              var t = Array.prototype.slice.call(arguments);
              t.unshift(e);
              analytics.push(t);
              return analytics;
            };
          };
          for (var e = 0; e < analytics.methods.length; e++) {
            var key = analytics.methods[e];
            analytics[key] = analytics.factory(key);
          }
          analytics.load = function (key, e) {
            var t = document.createElement('script');
            t.type = 'text/javascript';
            t.async = !0;
            t.src = 'https://cdn.segment.com/analytics.js/v1/' + key + '/analytics.min.js';
            var n = document.getElementsByTagName('script')[0];
            n.parentNode.insertBefore(t, n);
            analytics._loadOptions = e;
          };

          analytics._writeKey = _getSegmentSourceId();
          analytics.SNIPPET_VERSION = '4.15.3';
          //@ts-ignore
          analytics.load(_getSegmentSourceId());
          //@ts-ignore
          analytics.page();
          analytics.register({
            name: 'Cookie Compatibility',
            version: '0.1.0',
            type: 'utility',
            load: (_ctx, ajs) => {
              const user = ajs.user()

              // stringify any existing IDs
              user.anonymousId(anonymousSession.getId())
              user.id(user.id())
            },
            isLoaded: () => true
          })
        }
      }
    }();
  },
  async reset() {
    anonymousSession.reset();
    await analytics.reset();
  },
  setIncognito(isIncognito: boolean) {
    staticData.incognito = isIncognito;
  },
  identifyAnonymousUser(data?: any) {
    // This can't be found since it is a var in the segment snippet. but it works
    analytics.identify(
      null,
      _.assign({ ...staticData}, data, { anonymousId: anonymousSession.getId() }),
      {
        integrations: {
          'Facebook Pixel': false,
        },
      });
    DDLogs.log('Identify anonymous id', { anonymousId: Cookies.get('ajs_anonymous_id'), ...data });

    return Cookies.get('ajs_anonymous_id')
  },
  identifyCustomer(riseupAccountId: RiseupAccountId, memberId: MemberId, data?: any) {
    staticData.memberId = memberId;
    // This can't be found since it is a var in the segment snippet. but it works
    analytics.identify(
      riseupAccountId,
      _.assign({ ...staticData }, data, { anonymousId: anonymousSession.getId() }),
      {
        integrations: {
          'Facebook Pixel': false,
        },
      });
    DDLogs.log('identify', _.assign({}, { riseupAccountId }, data));
  },
  trackUserSignedUp(riseupAccountId: RiseupAccountId) {
    // This can't be found since it is a var in the segment snippet. but it works
    analytics.track(SIGNED_UP_EVENT_NAME, { userId: riseupAccountId, memberId: staticData.memberId });
    DDLogs.log(SIGNED_UP_EVENT_NAME, { riseupAccountId, memberId: staticData.memberId });
  },
  trackUserInteraction(eventName: string, data?: any) {
    // This can't be found since it is a var in the segment snippet. but it works
    analytics.track(`${eventName}`, _.assign({ ...staticData }, data, { eventType: 'interaction', ...context }),
      {
        integrations: {
          'Facebook Pixel': false,
        },
      });
    DDLogs.log(eventName, data);
  },
  trackUserInteractionToPixel(eventName: string, data?: any) {
    // This can't be found since it is a var in the segment snippet. but it works
    analytics.track(`${eventName}`, _.assign({ ...staticData }, data, { eventType: 'interaction', ...context }),
      {
        integrations: {
          'Facebook Pixel': true,
        },
      });
    DDLogs.log(eventName, data);
  },
  trackUserGot(eventName: string, data?: any) {
    // This can't be found since it is a var in the segment snippet. but it works
    analytics.track(`${eventName}`, _.assign({ ...staticData }, data, { eventType: 'push', ...context }),
      {
        integrations: {
          'Facebook Pixel': false,
        },
    anonymousId: anonymousSession.getId()
      });
    DDLogs.log(eventName, data);
  },
  trackUserGotToPixel(eventName: string, data?: any) {
    // This can't be found since it is a var in the segment snippet. but it works
    analytics.track(`${eventName}`, _.assign({ ...staticData }, data, { eventType: 'push', ...context }), {
        integrations: {
          'Facebook Pixel': true,
        },
      });
    DDLogs.log(eventName, data);
  },
};

export default Segment;

<template>
  <generic-error v-if="error" />
  <div
    v-else
    class="content"
  >
    <router-view />
  </div>
</template>

<script lang="ts" setup>
import { onErrorCaptured, ref } from 'vue';
import GenericError from './GenericError.vue';

const error = ref(null);
onErrorCaptured(err => {
  error.value = err;
});

</script>

<style lang="scss" scoped>
.content {
  position: relative;
  width: 100%;
  height: 100%;
}
</style>

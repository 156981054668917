<template>
  <content-loader v-if="showContentLoader" />
  <div
    ref="contentRef"
    class="page"
  >
    <riseup-header :is-green="true" />
    <div
      v-if="currentPage > 1"
      class="back-arrow-container"
      @click="handleBackClick"
    >
      <img
        src="../../assets/arrow_back.svg"
        alt="Back Arrow"
        class="back-arrow"
      >
    </div>
    <div
      v-if="currentPage === 1"
      class="page-1"
    >
      <img
        v-if="currentPage === 1"
        src="../../assets/tips_title.svg"
        alt="Tips Title"
        class="tips-title"
      >
      <div
        class="content"
      >
        <h1>First, let’s take a look at your spending snapshot</h1>
        <div class="subtitle-text">
          Here are your top spending categories from the last 6 months:
        </div>
        <div
          v-for="(insight, index) in firstInsights"
          :key="index"
          class="insights-container"
        >
          <div
            :class="['insight-box', getColorClass(index)]"
            @click="handleInsightClicked(insight)"
          >
            <div class="number-circle">
              {{ index + 1 }}
            </div>
            <p>{{ insight.insight }}</p>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="currentPage === 2"
      class="page-2"
    >
      <div
        class="content"
      >
        <h1>Your saving tips</h1>
        <div class="subtitle-text">
          Here are 3 ways you can start saving today:
        </div>
        <div
          v-for="(insight, index) in secondInsights"
          :key="index"
          class="insights-container"
        >
          <div
            :class="['insight-box', getColorClass(index+3)]"
            @click="handleInsightClicked(insight)"
          >
            <div class="emoji">
              {{ insight.emoji || '💰' }}
            </div>
            <p class="title">
              {{ insight.opening }}
            </p>
            <p class="analysis">
              <b>{{ insight.analysis }}</b>
            </p>
            <p class="insight">
              {{ insight.insight }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="currentPage === 3"
      class="page-3"
    >
      <div
        class="content"
      >
        <h1>2 more saving tips</h1>
        <div
          v-for="(insight, index) in finalInsights"
          :key="index"
          :class="['insight-box', getColorClass(index+6)]"
          @click="handleInsightClicked(insight)"
        >
          <div class="emoji">
            {{ insight.emoji || '💰' }}
          </div>
          <p class="title">
            {{ insight.opening }}
          </p>
          <p class="analysis">
            <b>{{ insight.analysis }}</b>
          </p>
          <p class="insight">
            {{ insight.insight }}
          </p>
        </div>
        <div class="chat-box green-light">
          <p class="looking">
            Want more value with RiseUp?
          </p>
          <div
            class="chat-button"
            @click="handleChatClicked"
          >
            <img
              src="../../assets/wa-icon.svg"
              alt="Chat Icon"
            >
            <p>Chat with us</p>
          </div>
        </div>
      </div>
      <div class="green-bottom">
        <img
          src="../../assets/green-bottom-top.svg"
        >
        <button
          class="blue-button"
          @click="handleTrialButtonClick"
        >
          Start your 3 month free trial now!
        </button>
        <p>
          <b>To unlock the full power of our technology, start your free trial now.</b>
        </p>
        <p>
          With RiseUp, you'll get a complete view of all your accounts, income and spending, plus personalised insights and real-time recommendations.
          You also get in-app and community support to help you stay on track.
        </p>
      </div>
    </div>
    <div
      v-if="currentPage < 3"
      class="footer"
    >
      <button
        class="blue-button"
        @click="handleClick"
      >
        {{ buttonText }}
      </button>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import RiseupHeader from '../Common/RiseupHeader.vue';
import ContentLoader from '../Common/ContentLoader.vue';
import Segment from '../../Segment';
import useContentLoader from '../../composables/useContentLoader';
import { useInsights } from '../../services/insightsService';

const { insights, populateInsights } = useInsights();
const currentPage = ref(1);
const contentRef = ref(null);
const route = useRoute();

const {
  showContentLoader,
} = useContentLoader();
const firstInsights = ref([]);
const secondInsights = ref([]);
const finalInsights = ref([]);

const buttonText = computed(() => {
  if (currentPage.value === 1) {
    return 'Go to my savings insights';
  }
  if (currentPage.value === 2) {
    return 'Give me more insights';
  }
  return 'Finish';
});

const getColorClass = index => {
  const colors = ['white', 'white', 'white', 'green-light', 'pink-light', 'blue-light', 'blue-light', 'pink-light'];
  return colors[index % colors.length];
};

function scrollToTop() {
  contentRef.value.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
}

const handleInsightClicked = insight => {
  Segment.trackUserInteraction('OtsInsightClicked', { insightIndex: insight.index });
};

const handleIgClicked = () => {
  Segment.trackUserInteraction('OtsIgClicked', { currentPage: currentPage.value });
  window.open('https://www.instagram.com/p/C-SoszEoZdh/?igsh=djd2YzhnYWltaHRi');
};

const handleClick = () => {
  scrollToTop();
  if (currentPage.value === 1) {
    Segment.trackUserInteraction('OtsGoToSavingsClicked');
    currentPage.value = 2;
  } else if (currentPage.value === 2) {
    Segment.trackUserInteraction('OtsGiveMeMoreClicked');
    currentPage.value = 3;
  }
};

const handleTrialButtonClick = () => {
  Segment.trackUserInteraction('OtsStartTrialClicked');
  window.location.href = 'https://start.letsriseup.com/?utm_source=ots&coupon=promo_1PctHEI1mBb8n2B7YmW03EcC';
};

const handleBackClick = () => {
  currentPage.value -= 1;
  Segment.trackUserInteraction('OtsBackClicked', { currentPage: currentPage.value });
};

const handleChatClicked = () => {
  Segment.trackUserInteraction('OtsChatClicked');
  window.location.href = 'https://wa.me/message/3IQKAQDODFK6F1';
};

onMounted(async () => {
  const queryParam = route.query || null;

  if (!insights.value || insights.value.length === 0) {
    await populateInsights();
  }

  if (insights.value && insights.value.length > 0) {
    firstInsights.value = insights.value.slice(0, 3);
    secondInsights.value = insights.value.slice(3, 6);
    finalInsights.value = insights.value.slice(6, 8);
  }
  Segment.trackUserGot('OtsSuccessPageEntered', { fromEmail: queryParam ? queryParam.fromEmail : false });
});

</script>

<style lang="scss" scoped>
@import '../../scss/riseup-vars';

.content {
  padding-left: 25px;
  padding-right: 25px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.page {
  background-color: $main_background_color;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  scroll-behavior: smooth;
}
.content h1 {
  padding-bottom: 10px;
  color: #202125;
  font-size: 28px;
  font-family: GrenetteSemiBold;
  font-weight: 600;
  line-height: 39px;
  word-wrap: break-word
}

.content .subtitle-text{
  padding-top: 10px;
  color: #202125;
  font-size: 18px;
  font-family: CircularStdBook;
  font-weight: 450;
  line-height: 26px;
  width: 100%;
  word-wrap: break-word;
  margin-bottom: 40px;
}

.animation {
  width: 200px;
  height: 200px;
}

.back-arrow-container {
  padding: 21px;
  cursor: pointer;
}

.back-arrow {
  width: 24px;
  height: 24px;
}

.blue-button {
  background-color: #5D7AFD;
  border-radius: 10px;
  height: 48px;
  color: white;
  padding: 10px 20px;
  border: none;
  margin-bottom: 20px;
  margin-top: 20px;
  width: 90%;
  cursor: pointer;
  font-size: 15px;
  font-family: CircularStdBook;
  font-weight: 900;
}

.footer {
  text-align: center;
  position: sticky;
  bottom: 0;
  padding-top: 10px;
  background: linear-gradient(to bottom, transparent , $main_background_color 20%,  $main_background_color );
}

.tips-title {
  width: 100%;
  margin-bottom: 20px;
}

.insight-box {
  width: 100%;
  padding: 21px 28px 40px;
  margin-bottom: 20px;
  border-radius: 20px;
  border: 1px rgba(55, 53, 44, 0.08) solid;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  display: inline-flex;
}
.ig-box {
  width: 100%;
  height: 175px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  background-color: $green_background_color;
  border-radius: 20px;
  position: relative; /* Badge position absolute within this container */
  padding: 28px 28px 30px;
  box-sizing: border-box; /* Ensures padding is included in total width/height */
}
.insights-container {
  display: flex;
  flex-direction: column;
}
.ig-box .content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content vertically */
  align-items: flex-start; /* Align items to the start (left) */
  width: 100%;
  height: 100%;

  text-align: left; /* Align text to the left */
  box-sizing: border-box; /* Ensures padding/margins are included in size */
}

.ig-box .badge {
  position: absolute; /* Badge overlay */
  top: 10px; /* Adjust as needed */
  right: 0; /* Positioned at the right edge */
  width: 100px;
}

.ig-box p {
  color: #F9F7ED;
  font-size: 22px;
  font-family: GrenetteSemiBold, serif;
  line-height: 26px;
  word-wrap: break-word;
  margin: 0;
  flex: 1;
  text-align: left; /* Ensure text is aligned to the left */
}

.ig-box p span {
  color: #205441;
  font-family: GrenetteSemiBold, serif;
}

.ig-box .white-button {
  margin-top: 10px; /* Adjust spacing */
  display: flex;
  align-items: center;
  justify-content: center; /* Center content within the button */
  background-color: white; /* Button background color */
  color: #205441; /* Button text color */
  padding: 10px 35px;
  border-radius: 10px;
  cursor: pointer;
  width: 100%; /* Adjust width if needed */
  font-family: CircularStdBook, serif;
  text-align: center; /* Center text within the button */
}

.ig-box .white-button img {
  margin-right: 8px; /* Space between icon and text */
}

.white {
  background-color: white;
}

.green-light {
  background-color: $green_light_background_color;
}

.pink-light {
  background-color: $pink_light_background_color;
}

.red-light {
  background-color: $red_light_background_color;
}

.blue-light {
  background-color: $blue_light_background_color;
}

.chat-box {
  padding: 28px 0 32px;
  background: #E8F4BA;
  border-radius: 20px;
  border: 1px rgba(55, 53, 44, 0.08) solid;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.chat-button {
  width: 80%;
  height: 52px;
  border-radius: 12px;
  border: 1px #205441 solid;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 16px;
  font-family: CircularStd,serif;
  font-weight: 700;
  line-height: 22px;
  cursor: pointer;
}

.looking {
  color: #205441;
  font-size: 17px;
  font-family: CircularStd,serif;
  font-weight: 900;
  line-height: 28px;
  text-align: center;
  margin-bottom: 18px;
}

.insight-box p {
  color: #202125;
  font-size: 16px;
  font-family: CircularStd,serif;
  line-height: 26px;
  word-wrap: break-word
}

.insight-box .emoji {
  font-size: 32px;
  font-family: CircularStd,serif;
}

.page-3 h1 {
  margin-bottom: 20px;
}

.insight-box .title {
  font-size: 28px;
  font-family: GrenetteSemiBold,serif;
  line-height: 40px;
}

.number-circle{
  width: 24px;
  height: 24px;
  background: #205441;
  border-radius: 9999px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: Arial,serif;
  font-weight: 700;
  //line-height: 28px;
  word-wrap: break-word
}

.green-bottom {
  height: 970px;
  width: 100%;
  margin-top: 40px;
  background-color: $green_background_color;
  border-top-left-radius: 40px; border-top-right-radius: 40px;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.green-bottom  .blue-button{
  height: 64px;
  margin-bottom: 10px;
  box-shadow: 0px 12px 24px rgba(32, 33, 37, 0.12)
}

.green-bottom img {
  margin-top: 60px;
  width: 100%;
  padding-left: 10px;
}

.green-bottom p {
  // To unlock the full power of our technology, start your free trial now.
  color: #E8F4BA;
  font-size: 18px;
  font-family: CircularStd;
  line-height: 28px;
  word-wrap: break-word;
  width: 90%;
  margin-top: 43px;
}

</style>

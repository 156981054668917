<template>
  <img
      src="../../assets/etoro-mock.png"
  />
</template>

<script setup>

</script>

<style lang="scss" scoped>
@import '../../scss/riseup-vars';

img {
  width: 100%;
}
</style>

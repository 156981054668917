import { clientWithoutInterceptors } from '../utils/http';
import { llmInsight } from '../types/insight';

export async function getInsights(): Promise<llmInsight[]> {
  const response = await clientWithoutInterceptors.get('/api/llm-insights');
  if (response.status !== 200) {
    throw new Error('Could not get insights');
  }

  return response.data.length === 0 ? [] : response.data.sort((a: llmInsight, b: llmInsight) => a.index - b.index);
}

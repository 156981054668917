<template>
  <refer-to-download-app
    :text="`Download RiseUp for Android now to start using your shared account with ${inviterFirstName}.`"
    :title="'Financial wellbeing awaits'"
    :screen-name="'InvitedMemberJoinRiseup'"
  />
</template>

<script lang="ts" setup>

import { onMounted, ref } from 'vue';
import _ from 'lodash';
import ReferToDownloadApp from '../Common/ReferToDownloadApp.vue';

import { clientWithoutInterceptors } from '../../utils/http';

const inviterFirstName = ref({});

onMounted(async () => {
  const response = await clientWithoutInterceptors.get('/api/restricted-customer/members');
  inviterFirstName.value = _.filter(response.data, { primary: true })[0].firstNamePiiValue;
});

</script>

<style lang="scss" scoped>

</style>

<template>
  <Descope
    flow-id="test-signup"
    @error="handleError"
    @success="handleSuccess"
  />
</template>

<script lang="ts" setup>
import { Descope } from '@descope/vue-sdk';
import _ from 'lodash';
import { clientWithoutInterceptors } from '../../utils/http';
import router from '../../router';

const handleError = (e: Error) => {
  console.log('Could not log in', e);
};

const handleSuccess = async (e: CustomEvent) => {
  await clientWithoutInterceptors.post(
    '/api/no-auth/descope/complete-descope-signup',
    {
      utmParams: _.pickBy(router.currentRoute.value.query, (value, key) => (key.startsWith('utm_') && value)),
    },
    {
      headers: {
        Authorization: `Bearer ${e.detail.sessionJwt}`,
      },
    },
  );

  const { redirectTo } = router.currentRoute.value.query;
  window.location.href = typeof redirectTo === 'string' ? redirectTo : '/';
};

</script>

<style lang="scss" scoped>

</style>

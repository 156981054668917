<template>
  <Descope
    flow-id="test-login"
    @error="handleError"
    @success="async () => {}"
  />
</template>

<script setup>
import { Descope } from '@descope/vue-sdk';
import DDLogs from '../../DDLogs';

const handleError = e => {
  DDLogs.error('Could not log to Descope flow', e);
};

</script>

<style lang="scss" scoped>

</style>

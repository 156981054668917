import { inject, provide, ref } from 'vue';
import { getUtmParams } from '../api/CustomersApi';
import { UtmParams } from '../types/utmParams';

interface UseUtm {
  utmParams: UtmParams;
  fetchUtmParams: () => Promise<void>;
}

const utmParams = ref<UtmParams | undefined>(undefined);

const fetchUtmParams = async () => {
  if (utmParams.value) { return; }
  const data = await getUtmParams();
  utmParams.value = data;
};

export const useUtmService = () => {
  provide('utmParams', utmParams);
  provide('fetchUtmParams', fetchUtmParams);
};

export const useUtm = (): UseUtm => ({
  utmParams: inject('utmParams')!,
  fetchUtmParams: inject('fetchUtmParams')!,
});

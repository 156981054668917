import DDLogs from '../DDLogs';

const errorMessages: Record<string, string> = {
  MagicLinkVerifyFailed: 'Something went wrong, please try again.',
  PasswordReplaceFailed: 'Something went wrong, please try again.',
  OTPVerifyEmbeddedFailed: 'This doesn\'t look right, please try again,',
  ConnectorFailed: 'Something went wrong, please try again.',
  EmailConnectorFailed: 'Something went wrong, please try again.',
  UpdateUserPropertiesFailed: 'Something went wrong, please try again.',
};

const errorLevels: Record<string, string> = {
  OTPVerifyEmbeddedFailed: 'warn',
  PasswordSignInFailed: 'warn',
  PasswordUpdateFailed: 'warn',
};

export const errorTransformer = (error: { type: string, text: string }) => {
  if (!error.type) {
    return '';
  }
  const errorLevel = errorLevels[error.type] ?? 'error';
  DDLogs.log(`Error happened in Descope: ${error.text}`, { ...error }, errorLevel);
  return errorMessages[error.type] ?? error.text;
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export const handleDescopeError = (e: any) => {
  if (e.detail.errorCode === 'E102004') {
    DDLogs.warn('Flow has being updated. We refreshed the page.', { e });
    window.location.reload();
    return;
  }
  DDLogs.error(`Fatal error occurred on Descope flow ${e.detail.message}`, { e });
};

export enum Device {
  iOS = 'ios',
  Android = 'android',
}

const appStoreLink = 'https://apps.apple.com/us/app/riseup-financial-wellbeing/id6449248281';
const playStoreLink = 'https://play.google.com/store/apps/details?id=com.riseup.android.uk';

const deviceStoreMap = {
  [Device.iOS]: appStoreLink,
  [Device.Android]: playStoreLink,
};

export function detectDevice(): Device | undefined {
  const userAgent = navigator.userAgent || navigator.vendor;
  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return Device.iOS;
  }
  if (/android/i.test(userAgent)) {
    return Device.Android;
  }
  return undefined;
}

export function openStoreLink(device: Device) {
  window.open(deviceStoreMap[device], '_blank');
}

<template>
  <div :class="['riseup-header', { 'green-background': isGreen }]">
    <img
      class="logo margin-left-ml"
      :src="logoSrc"
      @click="handleLogoClick"
    >
    <img
      v-if="isEtoroVariant"
      :src="eToroLogo"
      class="etoro"
    >
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import riseupLogoIcon from '../../assets/riseup-logo.svg';
import riseupWhiteLogoIcon from '../../assets/riseup-white-logo.svg';
import eToroLogo from '../../assets/etoro-logo.svg';
import { UtmVariantType } from '../../types/utmParams';

const props = defineProps<{
  isGreen?: boolean;
  variant?: UtmVariantType;
}>();

const isGreen = props.isGreen ?? false;

const logoSrc = computed(() => (isGreen ? riseupWhiteLogoIcon : riseupLogoIcon));

const isEtoroVariant = computed(() => props.variant === UtmVariantType.Etoro);

const handleLogoClick = () => {
  window.location.href = 'https://start.letsriseup.com/?utm_source=ots';
};
</script>

<style lang="scss" scoped>
@import '../../scss/riseup-vars.scss';

.riseup-header {
  position: sticky;
  top: 0;
  width: 100%;
  height: $riseup_header_height; // Ensure this value is set
  border-bottom: 1px solid $riseup_gray_0;
  background-color: $main_background_color;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  z-index: 1000;

  &.green-background {
    background-color: $green_background_color;
    border-bottom: 1px solid $riseup_gray_1;
  }

  .logo {
    width: 75px;
    display: flex;
  }

  .etoro {
    width: 80px;
    margin-left: 15px;

  }
}

</style>

<template>
  <div class="member-welcome">
    <img
      class="invite-member-bottom"
      src="../../assets/invite-member-bottom.svg"
    >
    <div class="padding-horizontal-ml overlay-content">
      <img
        class="welcome-image padding-bottom-xxl"
        src="../../assets/Welcome-to-riseup.svg"
      >
      <riseup-button
        :action="goToSignup"
        :size="'slim'"
        :title="'Let\'s go!'"
        :variant="'primary'"
        class="button margin-bottom-l margin-horizontal-ml"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>

import { useRoute, useRouter } from 'vue-router';
import RiseupButton from '@riseupil/base-ui/src/components/RiseupButton.vue';
import Segment from '../../Segment';

const router = useRouter();
const route = useRoute();

Segment.trackUserGot('WelcomeNewMemberEntered');

function goToSignup() {
  Segment.trackUserInteraction('WelcomeNewMemberLetsGoClicked');
  router.push({ path: '/signup', query: { ...route.query, redirectTo: 'join-riseup' } });
}
</script>

<style lang="scss" scoped>.member-welcome {
  position: relative; // Ensure the positioning context is set for the overlay
  height: 100%;
  display: flex;
  flex-direction: column;
  background-image: url('../../assets/invited-member-background.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.invite-member-bottom {
  position: absolute;
  bottom: 0;
  width: 100%; // Ensure it spans the full width
  //height: 50%; // Take up half the page
}

.overlay-content {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; // Center the items horizontally
}

.button {
  border-radius: 12px;
  font-family: CircularStd;
  position: sticky;
  bottom: 0;
  margin: 24px;
}

.welcome-image {
  margin-top: auto;
}</style>

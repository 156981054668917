<template>
  <div class="wrapper">
    <riseup-header :is-green="false" />
    <div class="content-loader">
      <lottie-player
        :src="cashflowLoader"
        autoplay
        loop
        class="animation"
      />
      <div class="content">
        <transition
          name="fade"
          mode="out-in"
        >
          <div
            v-if="showInitialText"
            key="initialText"
          >
            <p class="text">
              The account was connected successfully
            </p>
            <h1>We’re preparing your insights!</h1>
          </div>
          <p
            v-else
            :key="currentText"
            class="text"
            v-html="currentText"
          />
        </transition>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import cashflowLoader from '../../assets/animations/cashflow-loader.json';
import RiseupHeader from '../Common/RiseupHeader.vue';
import Segment from '../../Segment';
import { UtmVariantType } from '../../types/utmParams';

Segment.trackUserGot('OtsCashflowLoaderEntered');

// Define props to receive the variant
const props = defineProps<{
  variant?: string;
}>();

const texts = ref([
  'Hang tight! <br/>This may take us a minute or two...',
  'We’re about to send you some personalised insights.',
  'Our tip: Start small! <br/>Pick one thing and act on it.',
  'Just one small win a week brings big results!',
  'We\'ve collected your data and our AI engines are crunching the numbers.',
  'We’re analysing your transactions and categorising your expenses.',
  'We’re calculating your top 3 spending categories.',
  'Our AI is looking for places you can save.',
  'We’re preparing 3 spending tips for you.',
  'We’re finding opportunities for you to save money.',
  'Your financial insights are on their way.',
  'Almost there! <br/>Your insights are just around the corner.',
  'We’re making your financial data meaningful.',
  'Crunching the numbers to deliver your insights.',
  'Hang in there! <br/>Your insights are coming soon.',
  'We’re identifying patterns in your spending habits.',
  'Your custom financial insights are being prepared.',
  'Calculating your potential savings...',
  'Finding ways to help you spend smarter.',
  'Our AI is crafting personalized advice for you.',
  'Your spending trends are being analyzed.',
  'Your financial overview is nearly ready.',
  'Analyzing your data for a better financial future.',
  'Preparing your unique financial report.',
  'We’re tailoring insights to your spending habits.',
  'Your financial wellness checkup is on the way.',
  'Creating a roadmap to help you save more.',
  'Gathering insights to help you make better financial decisions.',
  'Your financial data is being transformed into actionable insights.',
  'Optimizing your financial picture just for you.',
]);

if (props.variant === UtmVariantType.Etoro) {
  texts.value = [
    'Analysing your data',
    'We’re preparing your personal recommendation',
  ];
}

const currentText = ref(texts.value[0]);
let textIndex = 0;
const showInitialText = ref(props.variant !== UtmVariantType.Etoro);

const changeText = () => {
  if (showInitialText.value) {
    showInitialText.value = false;
  } else if (textIndex < texts.value.length - 1) {
    textIndex++;
    currentText.value = texts.value[textIndex];
  }
};

onMounted(() => {
  setTimeout(() => {
    changeText();
    const intervalId = setInterval(() => {
      changeText();
      if (textIndex === texts.value.length - 1) {
        clearInterval(intervalId);
      }
    }, 5000);
  }, 3000);
// Initial display duration for the first message
});

</script>
<style lang="scss" scoped>
  @import '../../scss/riseup-vars';

  .wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: $main_background_color;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
  }
  .content-loader {
    display: flex;
    flex: 1;
    width: 100%;
    margin: 0 auto;
    align-items: center; /* Center content vertically */
    padding-left: 40px; /* Add left padding */
  }

  .content h1 {
    // We’re preparing your insights!
    color: #205441;
    font-size: 28px;
    font-family: GrenetteSemiBold, sans-serif;
    font-weight: 600;
    line-height: 34px;
    word-wrap: break-word;
  }

  .content .text{
    // The account was connected successfully
    color: #205441;
    font-size: 16px;
    font-family: CircularStdBook, sans-serif;
    font-weight: 450;
    line-height: 22px;
    word-wrap: break-word;
    padding-bottom: 22px;
  }

  .animation {
    position: absolute;
    right: -20px;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
  }

  .fade-enter-from, .fade-leave-to {
    opacity: 0;
  }
</style>

import { Member } from '@riseupil/costa-client';
import { clientWithoutInterceptors } from '../utils/http';
import { UtmParams } from '../types/utmParams';

export default {
  async getPartiallyAuthenticatedMember(): Promise<Member> {
    const response = await clientWithoutInterceptors.get('/api/partial-auth/members/active-member');
    return response.data;
  },
};

export async function getUtmParams(): Promise<UtmParams> {
  const response = await clientWithoutInterceptors.get('/api/restricted-customer/utm-params');
  return response.data;
}

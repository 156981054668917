export interface UtmParams {
  utm_source?: string;
  utm_variant?: string;
  utm_campaign?: string;
  utm_pvr?: string;
  [key: string]: string | undefined;
}

export enum UtmVariantType {
  Etoro = 'etoro',
  Vanilla = 'vanilla',
}

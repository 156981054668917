<template>
  <content-loader v-if="showContentLoader" />
  <div
    v-else
    class="signup"
  >
    <riseup-header />
    <Descope
      :error-transformer="errorTransformer"
      :flow-id="'prod-invite-member'"
      @error="handleDescopeError"
    />
  </div>
</template>

<script lang="ts" setup>
import { watch } from 'vue';
import { Descope, useSession } from '@descope/vue-sdk';
import ContentLoader from '../Common/ContentLoader.vue';
import RiseupHeader from '../Common/RiseupHeader.vue';
import useContentLoader from '../../composables/useContentLoader';
import { errorTransformer, handleDescopeError } from '../../utils/errorHandling';

const { toggleLoader, showContentLoader } = useContentLoader();

toggleLoader(true);
watch(useSession().isLoading, async newVal => {
  toggleLoader(newVal);
});

</script>

<style lang="scss" scoped>
@import '../../scss/riseup-vars';

.signup {
  background-color: $main_background_color;
  height: 100%;
}

</style>

import { createRouter, createWebHistory, NavigationGuardNext,
  RouteLocationNormalized } from 'vue-router';
import Cookies from 'js-cookie';
import { getSdk } from '@descope/vue-sdk';
import DescopeSignup from './components/descope/DescopeSignup.vue';
import DescopeLogin from './components/descope/DescopeLogin.vue';
import DescopeLogout from './components/descope/DescopeLogout.vue';
import DescopeMobileLogin from './components/descope/DescopeMobileLogin.vue';
import DescopeTestLogin from './components/descope/DescopeTestLogin.vue';
import DescopeTestSignup from './components/descope/DescopeTestSignup.vue';
import DescopeMobileTestLogin from './components/descope/DescopeMobileTestLogin.vue';
import AsyncLoadingWrapper from './components/Common/AsyncLoadingWrapper.vue';
import ErrorBoundary from './components/Common/ErrorBoundary.vue';
import config from './config';
import DDLogs from './DDLogs';
import DescopeMemberInvite from './components/descope/DescopeMemberInvite.vue';
import ReferToDownloadApp from './components/Common/ReferToDownloadApp.vue';
import GoToApp from './components/Common/GoToApp.vue';
import WelcomeNewMember from './components/member-join/WelcomeNewMember.vue';
import JoinRiseup from './components/member-join/JoinRiseup.vue';
import DescopeOtsSignup from './components/descope/DescopeOtsSignup.vue';
import { useSessionStore } from './stores/session';
import { isLoggedIn } from './api/loginApi';
import ConnectOtsAccount from './components/ots/ConnectOtsAccount.vue';
import OtsVanillaSuccess from './components/ots/OtsVanillaSuccess.vue';
import eToroSuccess from './components/ots/eToroSuccess.vue';
import eToroMock from './components/ots/eToroMock.vue';
import Segment from './Segment';
import OtsSuccess from './components/ots/OtsSuccess.vue';
import { _descopeAutheticate } from './utils/descopeUtils';
import { UtmVariantType } from './types/utmParams';

const COOKIE_EXPIRATION_DAYS = 7;

const routes = [
  {
    path: '/',
    name: 'UnAuthenticatedRoutes',
    component: ErrorBoundary,
    beforeEnter: gretaBeforeEnter,
    children: [
      {
        path: 'login/mobile',
        component: DescopeMobileLogin,
      },
      {
        path: 'login',
        component: DescopeLogin,
      },
      {
        path: 'logout',
        component: DescopeLogout,
      },
      {
        path: 'signup',
        component: DescopeSignup,
      },
      {
        path: 'ots-signup',
        component: DescopeOtsSignup,
      },
      {
        path: 'invite',
        component: DescopeMemberInvite,
      },
      {
        path: 'welcome-join',
        component: WelcomeNewMember,
      },
      {
        path: 'join-riseup',
        component: JoinRiseup,
      },
      {
        path: 'start-trial',
        component: ReferToDownloadApp,
        props: { title: 'Your 7 day free trial starts now.', text: 'Want to find out your monthly forecast? Download the app now.' },
      },
      {
        path: 'test/login',
        component: DescopeTestLogin,
      },
      {
        path: 'test/signup',
        component: DescopeTestSignup,
      },
      {
        path: 'test/mobile',
        component: DescopeMobileTestLogin,
      },
      {
        path: 'redirect',
        component: GoToApp,
        props: { title: 'Success!', text: 'You’ve successfully logged in, and can now continue to the app.' },
      },
      {
        path: '/ots',
        name: 'AuthenticatedRouts',
        component: AsyncLoadingWrapper,
        beforeEnter: _verifyOtsLogin,
        children: [
          {
            path: 'connect',
            component: ConnectOtsAccount,
          },
          {
            path: 'success',
            component: OtsSuccess,
          },
          {
            path: 'vanilla',
            name: 'VanillaRoutes',
            component: AsyncLoadingWrapper,
            beforeEnter: (
              to: RouteLocationNormalized,
              from: RouteLocationNormalized,
              next:NavigationGuardNext,
            ) =>
              _verifyUtmParams(to, from, next, UtmVariantType.Vanilla),
            children: [
              {
                path: 'success',
                component: OtsVanillaSuccess,
              },
            ],
          },
          {
            path: 'etoro',
            name: 'EtoroRoutes',
            component: AsyncLoadingWrapper,
            beforeEnter: (
              to: RouteLocationNormalized,
              from: RouteLocationNormalized,
              next:NavigationGuardNext,
            ) =>
              _verifyUtmParams(to, from, next, UtmVariantType.Etoro),
            children: [
              {
                path: 'success',
                component: eToroSuccess,
              },
              {
                path: 'mock',
                component: eToroMock,
              },
            ],
          },
        ],
      },
    ],
  },
];

async function _verifyUtmParams(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
  expectedUtmVariant: UtmVariantType,
): Promise<void> {
  const isAuthenticated = await _isAuthenticated();
  const sessionStore = useSessionStore();
  await sessionStore.fetchUtmParams();

  if (isAuthenticated && sessionStore.utmParams?.utm_variant === expectedUtmVariant) {
    return next();
  }

  window.location.href = config.get().otsLoginUrl;
  return next();
}

async function _verifyOtsLogin(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> {
  const { token } = to.query;

  if (token && typeof token === 'string') {
    // const tokenValue = token;
    const isDescopeAutheticated = await _descopeAutheticate(token);
    if (isDescopeAutheticated) {
      await _initSession();
      return next();
    }
  }
  const isAuthenticated = await _isAuthenticated();
  if (isAuthenticated) {
    return next();
  }
  // needs to redirect to login
  window.location.href = config.get().otsLoginUrl;
  return next();
}

async function _isAuthenticated(): Promise<boolean> {
  const loggedIn = await isLoggedIn();
  if (loggedIn) {
    await _initSession();
    return true;
  }

  return false;
}

async function _initSession() {
  const sessionStore = useSessionStore();
  await sessionStore.init();

  if (sessionStore.customerId && sessionStore.memberId) {
    Segment.identifyCustomer(sessionStore.customerId, sessionStore.memberId);
  }
}

function gretaBeforeEnter() {
  const gclid = new URLSearchParams(window.location.search).get('gclid');
  if (gclid) {
    DDLogs.info('User came with gclid', { gclid });
    const { hostname } = new URL(config.get().gretaPublicUrl);
    const domain = `.${hostname.split('.').slice(1).join('.')}`;
    Cookies.set('gclid', gclid, { expires: COOKIE_EXPIRATION_DAYS, domain, path: '/' });
  }
}

const router = createRouter({
  history: createWebHistory(),
  routes,
});
export default router;

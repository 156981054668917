import { defineStore } from 'pinia';
import { CustomerData } from '../types/session';
import CustomersApi, { getUtmParams } from '../api/CustomersApi';

export const useSessionStore = defineStore('session', {
  state: (): CustomerData => ({
    customerId: undefined,
    memberId: undefined,
    firstName: undefined,
    lastName: undefined,
    utmParams: undefined,
  }),
  actions: {
    async init(): Promise<void> {
      const member = await CustomersApi.getPartiallyAuthenticatedMember();
      this.customerId = member.customerId;
      this.memberId = member._id;
    },

    async fetchUtmParams(): Promise<void> {
      if (this.utmParams) { return; }
      const data = await getUtmParams();
      this.utmParams = data;
    },
  },
});

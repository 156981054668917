// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import { createApp } from 'vue';
import BaseUiVue3 from 'base-ui-vue3';
import { createPinia } from 'pinia';
import { createVfm } from 'vue-final-modal';
import descope from '@descope/vue-sdk';
import * as Sentry from '@sentry/vue';
import { CaptureConsole } from '@sentry/integrations';
import App from './App.vue';
import config from './config';
import router from './router';

import './style';
import DDLogs from './DDLogs';
import Segment from './Segment';

config.fetch().then(async () => {
  DDLogs.init();
  const app = createApp(App)
    .use(createVfm() as never)
    .use(Segment)
    .use(router)
    .use(createPinia())
    .use(BaseUiVue3)
    .use(descope, { projectId: config.get().descopeProjectId });
  Sentry.init({
    dsn: config.get().sentryDsn,
    integrations: [
      new Sentry.BrowserTracing({ tracePropagationTargets: [config.get().gretaPublicUrl] }),
      new CaptureConsole({ levels: ['error'] }),
    ],
  });
  app.mount('#app');
});

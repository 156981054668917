<template>
  <cashflow-loader
    v-if="showContentLoader"
    :variant="utmVariant"
  />
  <div
    v-else
    class="page"
  >
    <riseup-header :variant="utmVariant" />
    <div
      class="back-arrow-container"
      @click="handleBackClick"
    >
      <img
        src="../../assets/arrow_back.svg"
        alt="Back Arrow"
        class="back-arrow"
      >
    </div>
    <div class="content">
      <div v-if="utmVariant === UtmVariantType.Etoro">
        <h1>Ready to see how much you can invest?</h1>

        <div class="bullets-container">
          <div class="bullet">
            <img
              src="../../assets/green_check.svg"
              alt="Checkmark Icon"
            >
            <p class="text">
              To get the best results, connect the account you use most for spending.
            </p>
          </div>
          <div class="bullet">
            <img
              src="../../assets/green_check.svg"
              alt="Checkmark Icon"
            >
            <p class="text">
              Get personalised recommendations in minutes.
            </p>
          </div>
        </div>
      </div>
      <div v-else>
        <h1>Let’s connect your account</h1>
        <div class="text">
          To get the best results, connect the account you use most for spending.
        </div>
        <img
          class="hands"
          src="../../assets/hands.svg"
        >
      </div>
    </div>
    <div class="footer">
      <div class="flex-container">
        <div
          v-if="utmVariant === UtmVariantType.Etoro"
          class="etoro-flex-box"
        >
          <div class="icon">
            <img
              src="../../assets/etoro-shield.svg"
              alt="Shield Icon"
            >
          </div>
          <div class="text">
            <span>We don’t keep your data, we only connect once to create your recommendation.</span>
          </div>
        </div>
        <div
          v-else
          class="flex-box"
        >
          <div class="icon">
            <img
              src="../../assets/shield.svg"
              alt="Shield Icon"
            >
          </div>
          <div class="text">
            <span>We don’t keep your data, we only connect once to create your insights</span>
          </div>
        </div>
      </div>
      <button
        class="blue-button"
        @click="handleClick"
      >
        {{ utmVariant === UtmVariantType.Etoro ? 'Connect accounts' : 'Get started!' }}
      </button>
    </div>
  </div>
</template>

<script setup>
import { computed, onBeforeMount, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import RiseupHeader from '../Common/RiseupHeader.vue';
import CashflowLoader from './CashflowLoader.vue';
import Segment from '../../Segment';
import useContentLoader from '../../composables/useContentLoader';
import { authenticateTrueLayerCode, getTrueLayerUrl } from '../../api/trueLayer';
import config from '../../config';
import { useSessionStore } from '../../stores/session';
import { _descopeAutheticate } from '../../utils/descopeUtils';
import { useInsights } from '../../services/insightsService';
import { useUtm } from '../../services/utmService';
import { UtmVariantType } from '../../types/utmParams';

const sessionStore = useSessionStore();
const { insights, startPolling } = useInsights();

const router = useRouter();
const route = useRoute();
const { toggleLoader, showContentLoader } = useContentLoader();
const showConnectDetails = ref(false);
Segment.identifyCustomer(sessionStore.customerId, sessionStore.memberId);
const { utmParams, fetchUtmParams } = useUtm();
const utmVariant = computed(() => utmParams.value?.utm_variant ?? '');

const handleClick = async () => {
  showConnectDetails.value = false;
  Segment.trackUserGot('ConnectOtsAccountsConnectClicked');
  window.location.href = await getTrueLayerUrl();
};

const handleBackClick = () => {
  Segment.trackUserGot('ConnectOtsAccountsBackClicked');
  window.location.href = config.get().otsLoginUrl;
};

const handlePollingSuccess = () => {
  if (utmParams.value.utm_variant === UtmVariantType.Vanilla) {
    router.push('/ots/vanilla/success');
  } else {
    router.push('/ots/success');
  }
};

const handlePollingError = () => {
  throw new Error(`Could not get insights in time, ${sessionStore.customerId}`);
};

onBeforeMount(async () => {
  await fetchUtmParams();
  console.log('UTM Params Variant', utmParams.value.utm_variant, 'test', utmVariant);
  toggleLoader(false);

  console.log(utmParams);
  const queryParam = route.query || null;
  if (queryParam.token) {
    toggleLoader(true);
    const isDescopeAutheticated = await _descopeAutheticate(queryParam.token);
    if (isDescopeAutheticated) {
      const sessionStore = useSessionStore();
      await sessionStore.init();
      await fetchUtmParams();
    }
  }
  if (queryParam.code) {
    toggleLoader(true);
    const response = await authenticateTrueLayerCode(queryParam.code);
    if (response) {
      if (utmParams.value.utm_variant === UtmVariantType.Etoro) {
        await sleep(5000);
        router.push('/ots/etoro/success');
      } else {
        startPolling(300, 3000, handlePollingSuccess, handlePollingError);
      }
    }
  }
  Segment.trackUserGot('ConnectOtsAccountPageEntered');
});

// eslint-disable-next-line no-promise-executor-return
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

</script>

<style lang="scss" scoped>
@import '../../scss/riseup-vars';

.page {
  background-color: $main_background_color;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  overflow-y: auto;
  scroll-behavior: smooth;
  padding: 25px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.content h1 {
  color: #202125;
  font-size: 28px;
  font-family: GrenetteSemiBold;
  font-weight: 600;
  line-height: 39px;
  margin-bottom: 10px;
}

.content .text {
  color: #202125;
  font-size: 18px;
  font-family: CircularStdBook;
  font-weight: 450;
  line-height: 28px;
  margin-bottom: 20px;
}

.hands {
  width: 200px;
  align-self: center;
  margin-top: 22px;
}

.flex-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 90%;
  padding-top: 20px;
}

.etoro-flex-box {
  padding: 15px 15px 0px;
  background: #E8F4BA;
  border-radius: 18px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
}

.etoro-flex-box .text {
  margin-top: 20px;
  text-align: left; /* Optional if you need to ensure text alignment within the div */
}

.etoro-flex-box img{
  width: 30px;
  height: 30px;
}

.flex-box {
  padding: 10px;
  background: rgba(0, 192, 112, 0.1);
  border-radius: 18px;
  display: flex;
  align-items: center;
  width: 100%;
}

.icon {
  margin-right: 20px;
}

.icon img {
  width: 30px;
  height: 30px;
}

.back-arrow-container {
  padding-left: 21px;
  padding-top: 21px;
  cursor: pointer;
}

.back-arrow {
  width: 24px;
  height: 24px;
}

.footer {
  width: 100%;
  text-align: center;
  position: sticky;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-box .text {
  // We don’t keep your data, we only connect once to create your insights.
  color: #205441;
  font-size: 16px;
  font-family: CircularStdBook;
  font-weight: 450;
  line-height: 24px;
  word-wrap: break-word;
  text-align: left;
  margin: 0 auto;
}

.blue-button {
  background-color: #5D7AFD;
  border-radius: 10px;
  color: white;
  padding: 10px 20px;
  border: none;
  margin: 20px 0;
  width: 90%;
  cursor: pointer;
  font-size: 18px;
  font-family: CircularStdBook;
  font-weight: 700;
}

.bullets-container {
  margin-top: 30px;
  display: flex;
  flex-direction: column; /* Stack bullets vertically */
  gap: 15px; /* Space between bullets */
}

.bullet {
  display: flex;
  align-items: flex-start; /* Align items to the top of the container */
  gap: 20px; /* Space between checkmark and text */
}

.bullet img {
  width: 24px; /* Adjust size of the checkmark icon as needed */
  height: 24px; /* Adjust size of the checkmark icon as needed */
  object-fit: contain; /* Ensure the checkmark maintains its aspect ratio */
}
</style>

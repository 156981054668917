<template>
  <Descope
    flow-id="test-login"
    @error="handleError"
    @success="handleSuccess"
  />
</template>

<script setup>
import { Descope } from '@descope/vue-sdk';
import { clientWithoutInterceptors } from '../../utils/http';
import DDLogs from '../../DDLogs';
import router from '../../router';
import Segment from '../../Segment';

const handleError = e => {
  DDLogs.error('Could not log to Descope flow', e);
};

const handleSuccess = async e => {
  const { redirectTo } = router.currentRoute.value.query;
  DDLogs.info('DescopeTestLogin handleSuccess');
  Segment.trackUserGot('DescopeTestLogin', { redirectTo });
  await clientWithoutInterceptors.get('/api/no-auth/descope/login', {
    headers: {
      Authorization: `Bearer ${e.detail.sessionJwt}`,
    },
  });
  window.location.href = typeof redirectTo === 'string' ? redirectTo : '/';
};

</script>

<style lang="scss" scoped>

</style>

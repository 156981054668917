<template>
  <generic-error v-if="error" />
  <Suspense v-else>
    <router-view />
    <template v-slot:fallback>
      <div class="loader-wrapper">
        <tiny-loader class="loader-class" />
      </div>
    </template>
  </Suspense>
</template>

<script lang="ts" setup>
import TinyLoader from '@riseupil/base-ui/src/components/TinyLoader/TinyLoader.vue';
import { onErrorCaptured, ref } from 'vue';
import GenericError from './GenericError.vue';

const error = ref(null);
onErrorCaptured(err => {
  error.value = err;
});
</script>

<style lang="scss" scoped>
.loader-wrapper {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}
</style>

<template>
  <content-loader v-if="showContentLoader" />
  <div class="page">
    <riseup-header />
    <div class="content">
      <img
        src="../../assets/coins.png"
      >
      <h1>{{ title }}</h1>
      <div>
        {{ text }}
      </div>
    </div>
    <div class="footer">
      <button
        class="blue-button"
        @click="handleClick"
      >
        Open App
      </button>
    </div>
  </div>
</template>

<script setup>
import RiseupHeader from './RiseupHeader.vue';
import ContentLoader from './ContentLoader.vue';
import Segment from '../../Segment';
import useContentLoader from '../../composables/useContentLoader';

Segment.identifyAnonymousUser();
Segment.trackUserGot('GoToAppPageEntered');
const {
  showContentLoader,
} = useContentLoader();

const props = defineProps({
  title: {
    type: String,
  },
  text: {
    type: String,
  },
});

const handleClick = () => {
  Segment.trackUserGot('GotToAppButtonClicked');

  window.location.href = `riseup://descope_authenticate/login${window.location.search}`;
};

</script>

<style lang="scss" scoped>
@import '../../scss/riseup-vars';

.content {

  overflow-y: auto;
  scroll-behavior: smooth;
  padding-left: 25px;
  flex: 1;
}

.page {
  background-color: $main_background_color;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content h1 {
  padding-bottom: 10px;
}

.footer {
  /* Footer styling */
  width: 100%;
  text-align: center;
  position: sticky;
  bottom: 0;
}

.blue-button {
  background-color: #5D7AFD;
  border-radius: 10px;
  color: white;
  padding: 10px 20px;
  border: none;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 90%;
  cursor: pointer;
  font-size: 18px;
  font-family: CircularStd;
  font-weight: 700;
  line-height: 22px;
  word-wrap: break-word
}

img {
  width: 90%;
  padding-top: 60px;
  padding-bottom: 60px;
}
</style>

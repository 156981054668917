<template>
  <content-loader v-if="showContentLoader" />
  <div
    v-else
    class="signup"
  >
    <riseup-header />
    <Descope
      :client="{ anonymousUserId: Cookies.get('ajs_anonymous_id') , utmParams, contextPageReferrer}"
      :error-transformer="errorTransformer"
      :flow-id="descopeFlow"
      @error="handleDescopeError"
      @success="handleSuccess"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, onBeforeMount, watch } from 'vue';
import { Descope, useSession } from '@descope/vue-sdk';
import _ from 'lodash';
import Cookies from 'js-cookie';
import { useRoute, useRouter } from 'vue-router';
import ContentLoader from '../Common/ContentLoader.vue';
import { clientWithoutInterceptors } from '../../utils/http';
import DDLogs from '../../DDLogs';
import RiseupHeader from '../Common/RiseupHeader.vue';
import Segment from '../../Segment';
import useContentLoader from '../../composables/useContentLoader';
import { errorTransformer, handleDescopeError } from '../../utils/errorHandling';
import config from '../../config';

const router = useRouter();
const descopeSignUpFlows: Record<string, string> = {
  cashflow: config.get().signupFlowId,
};

const {
  toggleLoader,
  showContentLoader,
} = useContentLoader();
toggleLoader(true);
watch(useSession().isLoading, async newVal => {
  toggleLoader(newVal);
});

const { sessionToken } = useSession();
const utmParams = _.pickBy(router.currentRoute.value.query, (value, key) => (key.startsWith('utm_') && value));
const contextPageReferrer = document.referrer;
const route = useRoute();

onBeforeMount(async () => {
  const queryParam = route.query || null;

  if (!queryParam['descope-login-flow']) {
    await Segment.reset();
    Segment.identifyAnonymousUser();
  }
});

const handleSuccess = async (e: CustomEvent) => {
  toggleLoader(true);
  DDLogs.info('DescopeSignup handleSuccess');
  const token = sessionToken.value ?? e.detail.sessionJwt;
  const { coupon } = router.currentRoute.value.query;
  if (!token) {
    throw new Error('Descope Signup flow failed to signup, user sessionJwt is empty');
  }

  const resp = await clientWithoutInterceptors.post(
    '/api/no-auth/descope/complete-descope-signup',
    { utmParams },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  let { redirectTo } = router.currentRoute.value.query;
  Segment.identifyCustomer(resp.data.customerId, resp.data.memberId);
  // We use the signup flow to both create a new customer and to invite a new member to an existing customer we want to track the customer creation
  // only we track it here and not in the BE because we want to track the gclid
  if (!e.detail.user.customAttributes.customerId) {
    Segment.trackUserGot('CustomerCreated_Marketing', {
      market: 'UK',
      redirectTo,
      gclid: Cookies.get('gclid'),
      customerId: resp.data.customerId,
      utmParams,
    });
  }

  if (coupon) {
    redirectTo += `?coupon=${coupon}`;
  }

  window.location.href = typeof redirectTo === 'string' ? `${redirectTo}` : '/';
};

const descopeFlow = computed(() => {
  const { product } = router.currentRoute.value.query;

  return descopeSignUpFlows[typeof product === 'string' ? product : 'cashflow'] || config.get().signupFlowId;
});

const onMetaBrowser = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return (userAgent.indexOf('FBAN') > -1) || (userAgent.indexOf('FBAV') > -1) || (userAgent.indexOf('Instagram') > -1);
};

const currentHash = window.location.hash;

if (onMetaBrowser() && !currentHash) {
  window.location.hash = '#FBK';
}

</script>

<style lang="scss" scoped>
@import '../../scss/riseup-vars';

.signup {
  background-color: $main_background_color;
  height: 100%;
  overflow-y: auto;
  scroll-behavior: smooth;
}

</style>

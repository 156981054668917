<template>
  <content-loader v-if="showContentLoader" />
  <div
    ref="contentRef"
    class="page"
  >
    <riseup-header :variant="utmVariant" />
    <div
      v-if="currentPage > 1"
      class="back-arrow-container"
      @click="handleBackClick"
    >
      <img
        src="../../assets/arrow_back.svg"
        alt="Back Arrow"
        class="back-arrow"
      >
    </div>
    <div
      v-if="currentPage === 1"
    >
      <div
        class="content"
      >
        <div class="insights-container">
          <div class="insight-box white">
            <p>Money available in your current account:</p>
            <div class="money-container">
              <span class="money-small">£</span>
              <b><span class="money-big">2,100</span></b>
            </div>
          </div>

          <div class="insight-box white">
            <p>Analysing the past 5 months, you have an average disposable income per month of: </p>
            <div class="money-container">
              <span class="money-small">£</span><b><span class="money-small">816</span></b>
            </div>
            <img
              src="../../assets/etoro-graph.svg"
              alt="Graph"
            >
          </div>
        </div>
        <button
          class="blue-button"
          @click="currentPage += 1"
        >
          Next
        </button>
      </div>
    </div>
    <div
      v-if="currentPage === 2"
      class="page-2"
    >
      <div
        class="content"
      >
        <h2>One-time investment</h2>
        <div class="input-container">
          <span class="currency-sign">£</span>
          <input
            type="number"
            class="investment-input"
            placeholder="1,500"
            inputmode="numeric"
            pattern="[0-9]*"
          >
        </div>
        <button
          class="blue-button"
          @click="handleButtonClick"
        >
          Send money to eToro
        </button>
        <img
          src="../../assets/or-break.svg"
          alt="Graph"
          class="divider"
        >
        <h2>Invest monthly</h2>
        <div class="input-container">
          <span class="currency-sign">£</span>
          <input
            type="number"
            class="investment-input"
            placeholder="250"
            inputmode="numeric"
            pattern="[0-9]*"
          >
        </div>
        <button
          class="blue-button"
          @click="handleButtonClick"
        >
          Send money to eToro
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import RiseupHeader from '../Common/RiseupHeader.vue';
import ContentLoader from '../Common/ContentLoader.vue';
import Segment from '../../Segment';
import useContentLoader from '../../composables/useContentLoader';
import { useUtm } from '../../services/utmService';
import router from '../../router';

const currentPage = ref(1);
const contentRef = ref(null);
const route = useRoute();

const { utmParams, fetchUtmParams } = useUtm();
const utmVariant = computed(() => utmParams.value?.utm_variant ?? '');
const {
  showContentLoader,
} = useContentLoader();

const handleBackClick = () => {
  currentPage.value -= 1;
  Segment.trackUserInteraction('OtsBackClicked', { currentPage: currentPage.value });
};

const handleButtonClick = () => {
  router.push('/ots/etoro/mock');
};

onMounted(async () => {
  const queryParam = route.query || null;

  Segment.trackUserGot('OtsEtoroSuccessPageEntered', { fromEmail: queryParam ? queryParam.fromEmail : false });
});

</script>

<style lang="scss" scoped>
@import '../../scss/riseup-vars';

.content {
  padding: 25px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.page {
  background-color: $main_background_color;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  scroll-behavior: smooth;
}
.content h1 {
  padding-bottom: 10px;
  color: #202125;
  font-size: 28px;
  font-family: GrenetteSemiBold;
  font-weight: 600;
  line-height: 39px;
  word-wrap: break-word
}

.content h2 {
  margin-bottom: 34px;
  color: #202125;
  font-size: 24px;
  font-family: CircularStdBook;
  font-weight: 600;
  line-height: 39px;
  word-wrap: break-word
}

.content .subtitle-text{
  padding-top: 10px;
  color: #202125;
  font-size: 18px;
  font-family: CircularStdBook;
  font-weight: 450;
  line-height: 26px;
  width: 100%;
  word-wrap: break-word;
  margin-bottom: 40px;
}

.back-arrow-container {
  padding: 21px;
  cursor: pointer;
}

.back-arrow {
  width: 24px;
  height: 24px;
}

.blue-button {
  background-color: #5D7AFD;
  border-radius: 10px;
  height: 48px;
  color: white;
  padding: 10px 20px;
  border: none;
  margin-bottom: 20px;
  text-align: center;

  cursor: pointer;
  font-size: 15px;
  font-family: CircularStdBook;
  font-weight: 900;
}

.insight-box {
  width: 100%;
  padding: 28px;
  margin-bottom: 20px;
  border-radius: 20px;
  border: 1px rgba(55, 53, 44, 0.08) solid;
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: flex-start;
  gap: 10px; /* Adjust gap to control space between text and money line */
}

.insights-container {
  display: flex;
  flex-direction: column;
}

.white {
  background-color: white;
}

.insight-box p {
  color: #202125;
  font-size: 16px;
  font-family: CircularStdBook,serif;
  line-height: 22px;
  word-wrap: break-word
}

.insight-box img {
  margin-top: 30px;
}

.money-container {
  display: flex; /* Align items horizontally */
  align-items: baseline; /* Align baseline to ensure proper vertical alignment */
  gap: 5px;
}

.money-big {
  font-size: 60px;
  font-family: CircularStdBook, serif;
  line-height: 56px;
}

.money-small {
  font-size: 40px;
  font-family: CircularStdBook, serif;
  line-height: 56px;
}

.insight-box .title {
  font-size: 28px;
  font-family: GrenetteSemiBold,serif;
  line-height: 40px;
}

.input-container {
  display: flex;
  background-color: white;
  align-items: center;
  border-radius: 10px;
  border: 0px solid #ccc;
  padding: 10px;
  gap: 5px; /* Space between the sign and the input */
  margin: 10px 0; /* Space between the input and surrounding elements */
  font-family: CircularStdBook, serif; /* Use the same font as your design */
  margin-bottom: 34px;
  width: 100%;
}

.currency-sign {
  font-size: 36px;
  color: #202125;
  font-family: CircularStd, serif; /* Use the same font as your design */
}

.investment-input {
  background-color: transparent; /* Remove background color */
  border: none; /* Remove border */
  font-size: 36px;
  padding: 8px;
  width: 100%;
  outline: none; /* Remove default outline */
}

.divider {
  margin: 50px 0;
}
</style>

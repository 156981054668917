import { computed, ref } from 'vue';
import { createSharedComposable } from '@vueuse/core';

const useContentLoader = () => {
  const _isShown = ref(false);

  const toggleLoader = (turnOn: boolean) => {
    _isShown.value = turnOn;
  };

  // create read only with computed values
  const showContentLoader = computed(() => _isShown.value);

  return { toggleLoader, showContentLoader };
};
export default createSharedComposable(useContentLoader);

<template>
  <div class="refer-to-download-app">
    <riseup-header
      :color-variant="'yellow'"
      :logo-color="'green'"
    />
    <div class="padding-left-ml padding-top-l padding-right-ml">
      <div class="ri-glbl-medium-display">
        <div>{{ title }}</div>
      </div>
      <div class="ri-glbl-large-body margin-top-s margin-bottom-l">
        <div>{{ text }}</div>
      </div>
      <div class="store-container">
        <img
          :src="play"
          class="play-logo"
          @click="() => goToStore(Device.Android)"
        >
        <img
          :src="appstore"
          class="appstore-logo"
          @click="() => goToStore(Device.iOS)"
        >
      </div>
      <img :src="appPhoto">
      <button-footer
        :action="() => goToStore( detectDevice() || Device.Android)"
        :color="'yellow'"
        button-text="Download the app"
        class="footer"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import Cookies from 'js-cookie';
import play from '../../assets/google-play.svg';
import appPhoto from '../../assets/app-photo.svg';
import ButtonFooter from './ButtonFooter.vue';
import Segment from '../../Segment';
import RiseupHeader from './RiseupHeader.vue';
import appstore from '../../assets/app-store.svg';
import { detectDevice, Device, openStoreLink } from '../../utils/devices';
import CustomersApi from '../../api/CustomersApi';

function goToStore(device: Device) {
  CustomersApi.getPartiallyAuthenticatedMember().then(member => {
    Segment.trackUserInteraction(`${props.screenName}GoToStoreClicked`, { device, memberId: member._id });
  });
  openStoreLink(device);
}

const props = defineProps({
  title: {
    type: String,
  },
  text: {
    type: String,
  },
  screenName: {
    type: String,
  },
});
Segment.trackUserGot('ReferToDownloadAppEntered', { gclid: Cookies.get('gclid') });

</script>

<style lang="scss" scoped>
@import '@riseupil/base-ui/src/scss/riseup-colors';

.refer-to-download-app {
  background-color: $riseup-light-yellow;
  display: flex;
  flex-direction: column;

  .store-container {
    display: flex;
    justify-content: space-evenly;
    align-items: start;

    .play-logo {
      height: 46px;
    }

    .appstore-logo {
      height: 46px;
    }
  }
  height: 100%;
  overflow-y: auto;
  scroll-behavior: smooth;
}

</style>

<template>
  <content-loader v-if="showContentLoader" />
  <div
    v-else
    class="login"
  >
    <riseup-header />
    <Descope
      :client="{ anonymousUserId: Cookies.get('ajs_anonymous_id') || anonymousSession.getId(),isFromIOSApp}"
      :error-transformer="errorTransformer"
      :flow-id="descopeFlow"
      :locale="getUserLocale()"
      @error="handleDescopeError"
      @success="handleSuccess"
    />
  </div>
</template>

<script setup>
import { computed, watch } from 'vue';
import { Descope, useSession } from '@descope/vue-sdk';
import Cookies from 'js-cookie';
import { useRouter } from 'vue-router';
import { clientWithoutInterceptors } from '../../utils/http';
import DDLogs from '../../DDLogs';
import RiseupHeader from '../Common/RiseupHeader.vue';
import ContentLoader from '../Common/ContentLoader.vue';
import Segment from '../../Segment';
import useContentLoader from '../../composables/useContentLoader';
import anonymousSession from '../../anonymousSession';
import { errorTransformer, handleDescopeError } from '../../utils/errorHandling';
import { getUserLocale } from '../../utils/localeUtils';
import config from '../../config';

const router = useRouter();

Segment.identifyAnonymousUser();

const {
  toggleLoader,
  showContentLoader,
} = useContentLoader();
toggleLoader(true);
watch(useSession().isLoading, async newVal => {
  toggleLoader(newVal);
});

const descopeFlow = computed(() => config.get().loginFlowId);
const { redirectTo, isFromIOSApp } = router.currentRoute.value.query;

const { sessionToken } = useSession();

const handleSuccess = async e => {
  toggleLoader(true);
  DDLogs.info('DescopeLogin handleSuccess');
  const token = sessionToken.value ?? e.detail.sessionJwt;
  if (!token) {
    throw new Error('Descope Login flow failed to log in, sessionJwt is empty');
  }
  const response = await clientWithoutInterceptors.get('/api/no-auth/descope/login', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  Segment.identifyCustomer(response.data.customerId, response.data.memberId);
  Segment.trackUserGot('UniversalLoginSuccess', {
    redirectTo,
    customerId: response.data.customerId,
    memberId: response.data.memberId,
  });
  window.location.href = typeof redirectTo === 'string' ? redirectTo : '/';
};

</script>

<style lang="scss" scoped>
@import '../../scss/riseup-vars';

.login {
  background-color: $main_background_color;
  height: 100%;
  overflow-y: auto;
  scroll-behavior: smooth;
}
</style>

// services/insightsService.js
import { ref, provide, inject } from 'vue';
import { getInsights } from '../api/insightsApi';
import { llmInsight } from '../types/insight';

interface UseInsights {
  insights: llmInsight[];
  isPolling: boolean;
  startPolling: (maxAttempts: number, interval: number, onSuccess: () => void, handleError: () => void) => void;
  populateInsights: () => llmInsight;
}

const insights = ref<llmInsight[]>([]);
const isPolling = ref(false);

const startPolling = (maxAttempts = 10, interval = 3000, onSuccess: () => void = () => null, handleError: () => void = () => null) => {
  let attempts = 0;
  isPolling.value = true;

  const checkInsights = async () => {
    attempts++;
    try {
      const data = await getInsights();
      if (data && data.length > 0) {
        insights.value = data;
        stopPolling(); // Stop polling when insights are available
        onSuccess(); // Call the success callback
      } else if (attempts >= maxAttempts) {
        stopPolling(); // Stop polling after reaching max attempts
      }
    } catch (error) {
      // Handle error if needed
      console.error('Polling error:', error);
      if (attempts >= maxAttempts) {
        stopPolling(); // Stop polling after reaching max attempts
        handleError();
      }
    }
  };

  const stopPolling = () => {
    isPolling.value = false;
    clearInterval(polling);
  };

  const polling = setInterval(checkInsights, interval);

  // Initial check
  checkInsights();
};

const populateInsights = async () => {
  const data = await getInsights();
  insights.value = data;
  return data;
};

export const useInsightsService = () => {
  provide('insights', insights);
  provide('isPolling', isPolling);
  provide('startPolling', startPolling);
  provide('populateInsights', populateInsights);
};

export const useInsights = (): UseInsights => ({
  insights: inject('insights')!,
  isPolling: inject('isPolling')!,
  startPolling: inject('startPolling')!,
  populateInsights: inject('populateInsights')!,
});

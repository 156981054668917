import { getSdk } from '@descope/vue-sdk';
import { clientWithoutInterceptors } from './http';

export async function _descopeAutheticate(token: string): Promise<boolean> {
  if (token) {
    const descopeSdk = getSdk();
    const magicLinkResp = await descopeSdk.magicLink.verify(token);
    if (magicLinkResp.code !== 200) {
      return false;
    }
    // exchange for riseup token
    const response = await clientWithoutInterceptors.get('/api/no-auth/descope/login', {
      headers: {
        Authorization: `Bearer ${magicLinkResp?.data?.sessionJwt}`,
      },
    });
    return response.status === 200;
  }
  return false;
}

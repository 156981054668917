import { clientWithoutInterceptors } from '../utils/http';
import config from '../config';
import DDLogs from '../DDLogs';

export async function getTrueLayerUrl(extraData: string | undefined): Promise<Response> {
  const response = await clientWithoutInterceptors.post('/api/generate-true-layer-redirect-url/', {
    env: config.get().otsEnv,
    extraData,
  });
  return response.data.url;
}

export async function authenticateTrueLayerCode(code: string): Promise<boolean> {
  try {
    const response = await clientWithoutInterceptors.post('/api/authenticate/', {
      code,
    });
    return response.status === 200;
  } catch (error) {
    DDLogs.error('[OTS] Could not authenticate TL code', error);
    throw Error(`Could not authenticate TL code ${error}`);
  }
}
